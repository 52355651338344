import * as React from 'react'
import { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import { ComMain } from '@components/index'
import { FeedInFunc } from '@func/index'
import LogoBrand from "@svgs/logo_brand_gray.svg"
import LogoEnter from "@svgs/logo_enter_gray.svg"
import LogoBrandBg from "@svgs/logo_brand_bg.svg"

const ConceptPage = () => {

  useEffect(() => {
    FeedInFunc();
  }, []);

  return (
    <main className='mainConcept'>
      {/* CONTENTS */}

      <ComMain
        ttl="CONCEPT"
        subttl="コンセプト"
        caption=""
      >
      </ComMain>


      <section className='sec-lead'>
        <div className="bg-logo bg-logo1">
          <LogoBrandBg
            alt=""
          />
        </div>
        <div className="img-box parts-box comFeed">
          <div className="img"><StaticImage src="../assets/images/concept/parts.png" alt="" /></div>
        </div>

        <div className="c-content">

          <div className="lead-wrap">
            <div className="lead-box comFeed">

              <p className="lead">Odawara no<br />Shirube</p>
              <p className="ttl">小田原の<span>標</span>。</p>
              <p className="txt">小田原駅東口、栄町。<br />小田原城“生粋”の城下町に誕生する<br />「ル・サンク小田原栄町」全65邸は、<br />人々が行き交い、交流する街の象徴となり、<br />街並みに溶け込んで地域とつながり、<br />次の時代へと繋いでいく<br className='_sp'/>標となる住まいを目指します。</p>
            </div>
          </div>

        </div>
        <p className='c-cap cap'>外観完成予想CG</p>
      </section>


      {/* <section className='sec-gallery'>
        <div className="gallery-wrap comFeed">
          <div className="gallery-box">
            <div className="flex">
              <div className="img-box img-box-1">
                <div className="img"><StaticImage src="../assets/images/concept/gallery_1.jpg" alt="" /></div>
                <span>1</span>
              </div>
            </div>
            <div className="flex">
              <div className="img-box img-box-2">
                <div className="img"><StaticImage src="../assets/images/concept/gallery_2.jpg" alt="" /></div>
                <span>2</span>
              </div>
              <div className="img-box img-box-3">
                <div className="img"><StaticImage src="../assets/images/concept/gallery_3.jpg" alt="" /></div>
                <span>1</span>
              </div>
              <div className="img-box img-box-4">
                <div className="img"><StaticImage src="../assets/images/concept/gallery_4.jpg" alt="" /></div>
                <span>1</span>
              </div>
            </div>
          </div>
          <div className="gallery-note">
            <p className="c-note">1.小田原城址公園（約390m／徒歩5分） <br className='_sp' />2.小田原駅東口・ミナカ小田原（約340m／徒歩5分）</p>
          </div>
        </div>

      </section> */}


      <section className='sec-symbol'>
        <div className="c-content">

          <div className="lead-wrap">
            <div className="ttl-box comFeed">
              <p className="ttl">正統的な小田原らしさを<br className='_sp' />象徴する<br className='_pc' />住まいの<br className='_sp' />シンボルとして。</p>
            </div>
            <div className="flex-box comFeed">
              <div className="left">
                <div className="logo1"><LogoBrand /></div>
              </div>
              <div className="right">
                <p className="txt">水平ラインを基調に、『人』『入』を建物サイドの窓枠デザインの『コ』の形に組み合わせ、人入ると言う意味を持たせました。手摺の横桟と窓の縦ラインを途切れることなく続く永続性のある市松に組み、「永遠」、「子孫繁栄」、「発展拡大」といった意味を込めました。</p>
                <div className="logo2"><LogoEnter /></div>
              </div>
            </div>
          </div>

          {/* <div className="profile-wrap comFeed">
            <div className="img-box">
              <div className="img"><StaticImage src="../assets/images/concept/profile.jpg" alt="" /></div>
              <p className="c-cap">参考写真</p>
            </div>

            <div className="flex-box">
              <p className="ttl">PROFILE</p>
              <div className="img-box">
                <StaticImage
                  src="../assets/images/concept/hatoba.jpg"
                  alt="波戸場承龍"
                />
              </div>
              <div className="txt-box">
                <p className="p3">着物に家紋を手で描き入れる紋章上繪師としての技術を継承する一方、家紋の魅力を新しい形で表現したいという想いで、2007年より家紋のアート作品を制作。家紋やロゴデザインの域を超えて、森羅万象を描き出す職人兼デザイナーとして、あらゆる分野のデザインに挑戦し続けている。</p>
                <p className="p1">京源三代目<br />紋章上繪師</p>
                <p className="p2">波戸場承龍<br /><span>SHORYU  HATOBA</span></p>
              </div>
            </div>

          </div> */}

        </div>


        <div className="c-content">
          <div className="com-note-wrap">
            <p className="c-note gray">「ル・サンク小田原栄町」は小田原の由緒ある歴史と暮らしを継承し、街と共生する邸宅として計画しています。<br />そのシンボルとして、本プロジェクトオリジナルの紋を紋章上繪師「波戸場承龍氏」に依頼し開発しました。</p>
          </div>
        </div>


      </section>



      <section className='sec-view'>
        <div className="c-content">

          <div className="lead-wrap">
            <div className="lead-box comFeed">

              <p className="lead">小田原にいつまでも<br className='_sp' />輝きつづける邸宅として。</p>
              <p className="txt">人々に愛されつづける「小田原」に寄り添い、<br />この地にいつまでも輝きつづける邸宅として、<br />「ル･サンク小田原栄町」は<br className='_sp'/>小田原の心を受け継いで行く。</p>
            </div>
          </div>

        </div>
        <p className='c-cap'>外観完成予想CG</p>
      </section>

      <section className='sec-note'>
        <div className="c-content">
          <div className="com-note-wrap">
            <p className='c-note'>
              ※掲載の完成予想CGは、計画段階の図面を基に描いたもので、外観・外構・植栽・仕上げ等は実際とは異なる場合があります。外観形状の細部や設備機器等は表現されておりません。またタイルや各種部材の質感や色等は実際とは異なります。行政指導及び施工上の理由等のため、今後変更となる場合があります。敷地内の植栽は育成に必要な環境の下で、竣工から一定期間を経た状態のものを想定して描いており、特定の季節を表したものではありません。建設地周辺の建物や電柱等については、白線等で表現している部分がございます。周辺環境や景観は将来に渡り保証されるものではありません。予めご了承ください。<br />また、掲載の完成予想CGは、図面を基に描いたものに現地14階相当の眺望写真（2021年10月撮影）と小田原酒匂川花火大会を想定した花火イメージを合成したもので、実際の見え方とは異なります。
            </p>
          </div>
        </div>
      </section>


      {/* CONTENTS */}
    </main>
  )
}

export default ConceptPage
